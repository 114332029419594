import {
  Box,
  Button,
  HStack,
  Image,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { responsive } from '../../components/ThemeProvider/theme'
import Bokeh from './Bokeh'

import read from './read-your-own.svg'
import SubbuttonSVG from './SubbuttonSVG'
import subscribe from './subscribe.svg'
import SubscriptionForm from './SubscriptionForm'

const DoubleLayerBox = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [openSub, setOpenSub] = useState()
  return (
    <Box
      pos="relative"
      {...props}
      _before={{
        content: '""',
        position: 'absolute',
        display: 'block',
        top: '-3',
        left: '-3',
        right: 0,
        bottom: 0,
        border: '1px solid black',
      }}
      _after={{
        content: '""',
        position: 'absolute',
        display: 'block',
        top: 0,
        left: 0,
        right: '-3',
        bottom: '-3',
        bg: 'black',
      }}
    >
      <HStack
        border="1px solid black"
        letterSpacing="wider"
        px={responsive(4)}
        py={responsive(6)}
        spacing={responsive(4, 2)}
        pos="relative"
        zIndex={2}
        bg="white"
      >
        <Stack>
          <Text fontSize={responsive('1.125em', '1.5em')} fontWeight="bold">
            訂閱即可第一時間<br />
            收到最新測驗！
          </Text>
          <Box>
            <Button
              color="black"
              variant="unstyled"
              fontSize={responsive('0.875em', '1.25em')}
              fontWeight={400}
              borderBottom="1px solid black"
              rounded="none"
              onClick={onOpen}
            >
              個人資料使用規範
            </Button>
          </Box>
        </Stack>
        <Box flex={1}>
          <SubbuttonSVG cursor="pointer" onClick={() => setOpenSub(true)} />
        </Box>
      </HStack>
      <Modal
        preserveScrollBarGap
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent width="90%" py="2.5em" bg="white">
          <ModalCloseButton />
          <ModalBody>
            本網站依據個資法蒐集、處理及利用您的個人資料(如：電子郵件地址)。<br />
            上述個人資料之蒐集、處理及利用，將僅限本網站業務需要使用，並遵守個資法之規定，妥善保護您的個人資訊。<br />
            依據個資法第3條規定，您可向本公司行使之個資權利包括：查詢、閱覽、複製、補充、更正、處理、利用及刪除。<br />
            您可與本公司聯繫(superquiz@relab.cc)，本公司將儘速處理與回覆您的請求。
          </ModalBody>
        </ModalContent>
      </Modal>
      <SubscriptionForm isOpen={openSub} onClose={() => setOpenSub(false)} />
    </Box>
  )
}

const Subscribe = () => {
  return (
    <Box pos="relative">
      <Stack my="6em" spacing={12} direction={responsive('column', 'row')} maxW="900px" mx="auto" position="relative">
        <DoubleLayerBox flex="1" />
        <Stack fontSize={responsive('lg', '2xl')}>
          <Image userSelect="none" pointerEvents="none" width={responsive('12em')} src={read} alt="Start to Read your own life" />
          <Text fontWeight="bold">「先當自己的伯樂，你就會成為千里馬。」</Text>
        </Stack>
      </Stack>
      <Box pos="absolute" left={responsive(0, '-20%')} pointerEvents="none" transform="translateY(-10vh)">
        <Bokeh variant="c" opacity="0.5" size="90vmin" />
      </Box>
    </Box>
  )
}

export default Subscribe
