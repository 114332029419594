import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import SVG from '../../components/SVG'
import { Media, responsive } from '../../components/ThemeProvider/theme'
import SubscriptionForm from './SubscriptionForm'
import who from './who.svg'
import whoDesk from './who-desk.svg'

const Sub = (props) => (
  <SVG viewBox="0 0 350 350" {...props}>
    <g>
      <circle
        cx="175"
        cy="175"
        r="173.52"
        fill="none"
        stroke="#000"
        strokeWidth="1.76"
      />
      <g fill="currentColor">
        <g>
          <path d="M91.44,305.58l-7-4.27-.83,1.36-3.28-2,6.82-11.2,10.29,6.27Zm-2.9-11-2.21,3.64,3.7,2.25,2.21-3.64Zm1.3-9.58,10.42,6.35-1.78,2.92L88.06,287.9Zm2.64-4.35L103,287l-1.78,2.92-10.48-6.38Zm8-1.29,6.07,3.7-1.86,3L92,278.3l1.85-3.05,5.59,3.4a36.73,36.73,0,0,0,.41-5.35l3.87.44a35.07,35.07,0,0,1-.26,5.76Zm22.84,15.11-4.19-2.55L107.57,311c-1.34,2.21-2.54,3-4.39,2.75s-4.1-1.52-7.25-3.43a16,16,0,0,0,.9-4.92c2.1,1.45,4.68,3,5.37,3.39s1.08.35,1.45-.27l11.56-19-8.5-5.18,2.29-3.76,16.65,10.13Z" />
          <path d="M137.85,304.52l-4.12,22.42-4.14-.76,6.19-33.7,14.42,2.65-2.07,11.28Zm24.23,22.69c-.37,2-.89,3-2.21,3.42s-3,.22-5.3-.2a12.9,12.9,0,0,0-.16-2.7,2.85,2.85,0,0,1-2.43.4l-2.35-.43c-3-.54-3.37-1.66-2.82-4.65l.56-3.06-1.94-.36c-1.2,3.56-3.11,5.76-8.18,6.45a10.76,10.76,0,0,0-1.55-3.33c4-.24,5.31-1.58,6.11-3.79l-2-.36,1.09-5.91c-.54.21-1,.39-1.57.57a15.17,15.17,0,0,0-2.16-2.56c2.82-.84,6-2.41,7.67-4.12l3.25,1.68a20.78,20.78,0,0,1-5.08,3.47l10.84,2a47.71,47.71,0,0,0-4.26-4.37l2.65-1.37a46,46,0,0,1,6.56,7L156,316.47a10.55,10.55,0,0,0-.92-1.29l-1.11,6-2.92-.53-.56,3.06c-.14.79-.12.91.36,1l1.23.22c.53.1.7-.22,1.23-2.48a9.35,9.35,0,0,0,2.88,1.81,10.23,10.23,0,0,1-.89,2.31,12.47,12.47,0,0,0,1.83.33c.45.08.63,0,.71-.52l3.23-17.57-10.46-1.92,2.07-11.28,14.73,2.7Zm-17.62-24.37.3-1.65-6.09-1.11-.3,1.64Zm-5.05-6.76-.28,1.49,6.09,1.12.28-1.49Zm4.32,20.61,7,1.29.39-2.17-7-1.29ZM161.57,306l.3-1.64-6.24-1.15-.3,1.64Zm-5.21-6.79-.27,1.5,6.24,1.14.27-1.49Z" />
          <path d="M185.55,303.48a23.46,23.46,0,0,0-6.47-2.73L181,297a26.41,26.41,0,0,1,6.54,2.42Zm.37,10a25.53,25.53,0,0,0-6.48-2.54l1.85-3.69a22.53,22.53,0,0,1,6.57,2.18Zm4.21,4.88c-.52,4.23-1.39,8.82-2.26,12.77l-4.41-1.56a116.62,116.62,0,0,0,2.66-12.88Zm13.19,1-11.29,2L187.63,297l11.3-2Zm-5.95,2.54a29.09,29.09,0,0,1-3.65,8.69,27.91,27.91,0,0,0-3.88-1.65,21.12,21.12,0,0,0,3.29-7.43Zm-1.64-22.37-3.66.66.56,3.14,3.67-.66Zm1.21,6.69-3.67.66.58,3.18,3.66-.66Zm1.22,6.77-3.67.66.58,3.22,3.67-.66Zm3.52,6.67a40.15,40.15,0,0,1,5,5l-3.14,2.73a41.82,41.82,0,0,0-4.85-5.27Zm7.28-1.78-3.85.69L201.21,297l3.86-.7Zm1.79-26.5,5.39,29.88c.41,2.28.21,3.51-1,4.42s-3.1,1.45-6,2a17.64,17.64,0,0,0-2-4c1.92-.27,3.72-.59,4.32-.7s.78-.37.68-.93l-5.39-29.89Z" />
          <path d="M251.13,297.64c.32,1,.6,1.94.83,2.91a18.36,18.36,0,0,1,4.49.53l-.37,3.64a22.21,22.21,0,0,0-3.53-.62,17.86,17.86,0,0,1-.3,5.82,24.47,24.47,0,0,0-3.69-.61,2.3,2.3,0,0,1,.1.92,2.69,2.69,0,0,1-1.13,2.15,15.86,15.86,0,0,1-2.63,1.76,9.28,9.28,0,0,0-2.47-2.41,16.92,16.92,0,0,0,1.88-1,1,1,0,0,0,.59-.85,9.69,9.69,0,0,0-1.23-3.07l-.88,1.11a33,33,0,0,0-3.84-3.41l1.09-1.28a32.29,32.29,0,0,1,3.05,2.47c-.57-1.08-1.32-2.4-2.2-4l-8.49,5.13-10.94-18.12,11.25-6.8,1.9,3.16-3.31,2,1.29,2.15,2.8-1.69.45.75a27.7,27.7,0,0,0,3.67-12.74l4.17-.88a13,13,0,0,1,0,1.4,29.88,29.88,0,0,0,9.94,0,17.15,17.15,0,0,0,.71,4.41,35,35,0,0,1-4.11,0l1.3,2.14-9.56,5.78-1-1.63c-.45.94-.93,1.85-1.4,2.76a18.55,18.55,0,0,0-3.5-.95l1.08,1.79-2.8,1.69,1.16,1.92,2.9-1.75,1.71,2.83-2.9,1.75,1.24,2.05,3.61-2.19s.51.85.71,1.26c3.5,6.15,5.22,9.37,5.74,11.1a16.85,16.85,0,0,0-.94-10Zm-24.78-7.58,1.3,2.15,2-1.22-1.3-2.15Zm5.05,3.79-2,1.22,1.16,1.92,2-1.22Zm2.09,8,2-1.21-1.23-2-2,1.21Zm2.15,14.07a45.81,45.81,0,0,0-2.91-7.83l2.33-.48a49.5,49.5,0,0,1,3.09,7.76Zm1.29-9.62c1.28,1.75,2.85,4.13,3.84,5.76l-1.58,1.22a69.21,69.21,0,0,0-3.78-5.89Zm1.67-1.5a55.94,55.94,0,0,1,4.11,4.67l-1.43,1.31A43.66,43.66,0,0,0,237.2,306Zm1.63-13,7-4.2,4.78,7.9-7,4.21Zm8.34-11.39a53,53,0,0,1-5.37-.74,29.17,29.17,0,0,1-1.36,4.81ZM246,295.74l1.79-1.08L246,291.61l-1.79,1.08Zm2.19-8.78,7.22-4.36,4.78,7.9L253,294.86Zm5.81,4,2-1.24-1.85-3.06-2.05,1.24Zm5.14,1.68a31.39,31.39,0,0,1,1.37,4.33,34.93,34.93,0,0,1,5.5.58l-.65,4a27.73,27.73,0,0,0-4.3-.73,15.92,15.92,0,0,1-.23,3.95,20.25,20.25,0,0,0-4.09-.45c.76-3,.08-6.62-1.4-10.17Z" />
        </g>
        <g>
          <path d="M40.27,128.91,36,137q-2.58,4.88-7.12,3.28-6.19-2.19-2.36-13.08,2.17-6.15,4.79-8.55A5.66,5.66,0,0,1,37,117.22l-3,8.65q-1.91-.66-3.12,2.79c-.7,2-.61,3.14.29,3.46a1,1,0,0,0,1.32-.63l4.46-8.43q3-5.76,7.62-4.14a5.79,5.79,0,0,1,4.08,4.73q.62,3.51-1.59,9.76a24.62,24.62,0,0,1-3.94,7.75c-1.91,2.22-4.12,2.89-6.62,2l3.16-9a1.7,1.7,0,0,0,1.95-.65,8.57,8.57,0,0,0,1-2.06c.7-2,.53-3.14-.49-3.5Q41,127.64,40.27,128.91Z" />
          <path d="M48.86,93.72l13.21,7.73-3.42,5.84-1.47-.86,0,0a5.22,5.22,0,0,1,.23,2.66,10.5,10.5,0,0,1-1.41,4,7.7,7.7,0,0,1-3.65,3.48,5.55,5.55,0,0,1-4.94-.65l-8.58-5,3.53-6,7,4.09c1.59.93,2.84.63,3.75-.92q1.63-2.81-1.53-4.65l-6.23-3.65Z" />
          <path d="M64.31,98.23,48.61,84.76l4.56-5.31,5.39,4.63q-.47-2.76,2.34-6a9.25,9.25,0,0,1,5.43-3.33,8.59,8.59,0,0,1,9.44,8.08,9.17,9.17,0,0,1-2.45,5.87C71.43,90.88,69.49,92,67.52,92l0,0,1.23,1.06ZM63.13,88c1.75,1.5,3.46,1.29,5.13-.65s1.62-3.66-.14-5.17a3.27,3.27,0,0,0-2.49-.88A3.89,3.89,0,0,0,63,82.81Q60.49,85.74,63.13,88Z" />
          <path d="M86.3,68.21,80.57,72a6.9,6.9,0,0,1-4.17,1.45,4.34,4.34,0,0,1-2.86-1.78A4.1,4.1,0,0,1,73,67c.75-1.67,2.47-3.6,5.14-5.78q7.72-6.3,11.14-2.37l-6.11,5c-.57-.69-1.52-.49-2.87.6s-1.7,1.85-1.26,2.4,1,.26,2.06-.45l5.53-3.75q4.32-2.94,7,.29a4.33,4.33,0,0,1,.64,4.88Q93.06,70.44,88.7,74c-2.84,2.31-5.16,3.67-7,4.08A4.6,4.6,0,0,1,77,76.56l6.38-5.2a1.53,1.53,0,0,0,1.65.35,5.94,5.94,0,0,0,1.51-1c1.2-1,1.56-1.77,1.06-2.38A.89.89,0,0,0,86.3,68.21Z" />
          <path d="M113.85,46.45,107.44,50a2.27,2.27,0,0,0-2.09-1,5.46,5.46,0,0,0-2.43.8c-2.47,1.36-3.09,3.15-1.87,5.35a3.75,3.75,0,0,0,2.3,2,4.26,4.26,0,0,0,3.21-.6q2.86-1.59,2.18-4.11l6.41-3.55a6.92,6.92,0,0,1-.7,7.17,17.88,17.88,0,0,1-5.72,4.47Q98.7,66,94.67,58.72T100.73,46q4.5-2.49,7.55-2.48A6.82,6.82,0,0,1,113.85,46.45Z" />
          <path d="M121.38,53.43,116.23,39l6.38-2.28,1,2.77.05,0a7.91,7.91,0,0,1,5-5.35,15.34,15.34,0,0,1,2.22-.62L133,39.43a15,15,0,0,0-3.48.65c-3.24,1.15-4.39,3-3.47,5.6l1.93,5.4Z" />
          <path d="M135.57,31.13l-.87-3.76,6.81-1.58.88,3.76Zm3.81,16.39-3.46-14.91L142.73,31l3.46,14.91Z" />
          <path d="M150.51,44.76l-1.89-20.6,7-.63.65,7.07q1.59-2.3,5.87-2.7a9.24,9.24,0,0,1,6.22,1.4,7.57,7.57,0,0,1,3.22,5.95,7.56,7.56,0,0,1-2.08,6.42,9.07,9.07,0,0,1-5.84,2.5q-4.35.4-6.47-1.65h-.06l.15,1.62Zm6.27-8.16c.22,2.3,1.59,3.33,4.14,3.1s3.71-1.5,3.5-3.8a3.27,3.27,0,0,0-1.17-2.37,4,4,0,0,0-2.94-.74Q156.47,33.14,156.78,36.6Z" />
          <path d="M189.86,39.56l7.42.68a6.54,6.54,0,0,1-3.83,3.47,16.66,16.66,0,0,1-7.51.72q-11.5-1.05-10.76-9.29a7.18,7.18,0,0,1,3.41-5.82q3-1.89,8.21-1.42Q198.53,29,197.49,38.82l-15.33-1.39A2.28,2.28,0,0,0,183,39.3a5,5,0,0,0,3.17,1.27A4.34,4.34,0,0,0,189.86,39.56Zm-7.38-5.64,8.31.75a2.53,2.53,0,0,0-1-2.06,5.27,5.27,0,0,0-2.83-1C184.45,31.4,183,32.17,182.48,33.92Z" />
          <path d="M215.25,38.22l1.46-4.12,3.06,1.09,1.65-4.64L228,32.89l-1.65,4.64,3.56,1.26-1.46,4.12-3.56-1.27-1.81,5.11c-.23.64-.22,1.1,0,1.38a3.78,3.78,0,0,0,1.45.76l1.77.62L225,53.31a37.39,37.39,0,0,1-4.91-1.39c-2.09-.74-3.42-1.54-4-2.38s-.59-2.38.09-4.29l2.11-6Z" />
          <path d="M230.8,47.14q2-3.75,5.37-4.21t8.53,2.32q5.16,2.78,6.62,5.84c1,2,.78,4.3-.56,6.8s-3.14,3.9-5.38,4.22-5.08-.45-8.53-2.31S231.2,56,230.23,54,229.46,49.63,230.8,47.14Zm6.46,3.48q-1.78,3.3,1.74,5.19t5.3-1.4c1.18-2.18.59-3.91-1.76-5.17S238.43,48.43,237.26,50.62Z" />
          <path d="M273.39,93.37l-.27-5.92a25.31,25.31,0,0,1-4.59-3.84q-4-4-5-7.15-1.78-5.31,3-10.08t10.1-2.92q3.15,1.11,7.1,5.1t5,7.15q1.8,5.31-3,10.08-3.69,3.66-7.62,3.4l.21,3.94ZM272.76,79l4.77-.16.15,2.58a8,8,0,0,0,2.14-1.58q4-4,.31-7.71c-2.46-2.49-5-2.41-7.71.24s-2.79,5.22-.32,7.71c.16.16.4.38.71.64Z" />
          <path d="M304,103.46l-12.76,8.46-3.74-5.65,1.42-.94,0,0a5.29,5.29,0,0,1-2.49-1,10.61,10.61,0,0,1-2.94-3,7.58,7.58,0,0,1-1.5-4.81,5.48,5.48,0,0,1,2.76-4.14L293,86.85l3.86,5.83-6.77,4.49c-1.53,1-1.81,2.27-.82,3.77q1.78,2.7,4.85.68l6-4Z" />
          <path d="M293.06,116l13.77-6.68,3.05,6.29-13.77,6.68Zm15.13-7.34,3.47-1.69,3,6.29L311.24,115Z" />
          <path d="M297.82,125.57l3.67-1.26,9.16,6-2.64-7.7,4.45-1.53,5.68,16.6L314.33,139l-8.72-5.68,2.74,8-4.6,1.58Z" />
        </g>
      </g>
      <path
        d="M178.1,229.64c-.8-.74-1.77-1.56-2.66-2.46q-23-23-45.88-46.1c-9.18-9.19-11.42-20-7.08-32,4-11.13,16.06-19.42,28-19.49A27.49,27.49,0,0,1,171.07,138c2.19,2.25,4.13,4.72,6.31,7.22,3.8-3.47,6.76-6.37,9.93-9,11.68-9.77,28.59-8.57,39.71,2.73s12.34,28.55,1.9,39.67c-13.55,14.44-27.76,28.26-41.72,42.32C184.28,223.81,181.24,226.62,178.1,229.64Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.93"
      />
    </g>
  </SVG>
)

const Contact = (props) => (
  <SVG viewBox="0 0 350 350" {...props}>
    <g>
      <circle
        cx="175"
        cy="175"
        r="173.52"
        fill="none"
        stroke="#000"
        strokeWidth="1.76"
      />
      <g fill="currentColor">
        <g>
          <path d="M104.38,280.76,88.77,306.4l-3.22-2,3.4-5.58c-2.56-1-5.09-1.94-7.21-2.84l1.32-3.78,1.82.8,10.73-17.62-1.2-.73,2.1-3.44,11,6.68-2.1,3.44ZM91,295.58l2.21-3.64-2.47-1.5-2.45,4Zm12.62.52,3.18,1.94-2.94,4.84c-2,3.34-5.43,6.57-11.77,5.73a14.17,14.17,0,0,0-.41-3.85c3.12.43,5.12-.08,6.59-1.11l-4.09-2.48,4.8-7.89,3.09,1.88-2.85,4.67,1.56.95Zm-6.4-10.74-2.46-1.51-2.1,3.44,2.47,1.51Zm4-6.56-2.46-1.5-2.08,3.41,2.47,1.5Zm8.73,12.78a55.87,55.87,0,0,1-1.71,6.31l-2.74-.69c.16-.48.38-1.06.62-1.67-5.69-2.57-6.67-2.86-7.36-2.88.32-.83.94-2.72,1.22-3.84a5.18,5.18,0,0,0,2.79-.35,17.37,17.37,0,0,0,2.37-.82,6.38,6.38,0,0,0-2.57-.9,32.85,32.85,0,0,0,1.22-4,3.13,3.13,0,0,0,2-.33,26.48,26.48,0,0,0,6-3.42l2,2.86a41.21,41.21,0,0,1-6.93,3.48l1.54.84c1.15-.58,2.31-1.17,3.44-1.86l1.74,2.84a54.4,54.4,0,0,1-9.85,4.06l3.16,1.52c.2-.68.36-1.38.51-2Zm3,10.5L116,304l-5.33,8.76-3.12-1.89.68-1.11-1.63-1-3.77,6.2-3.31-2,8.55-14.06,3.32,2-2.77,4.54,1.62,1Zm2.61,1.06c.16-.57.37-1.2.55-1.94-5.73-2.28-6.78-2.52-7.53-2.54a36.33,36.33,0,0,0,1.2-3.72c.61.24,1.48,0,2.68-.45a23,23,0,0,0,2.7-1.16c-2.15-1-2.77-1.15-3.21-1.15.27-.82.85-2.64,1.09-3.7a2.72,2.72,0,0,0,1.93-.25,25.32,25.32,0,0,0,6-3.27l1.59,2.71a36.39,36.39,0,0,1-6.21,3.11l1.82,1c1-.58,2-1.18,3-1.85l1.69,2.77a54.57,54.57,0,0,1-9,4.48l3,1.29c.16-.7.27-1.39.39-2l2.57.59c-.33,2.16-.89,5-1.33,6.58Z" />
          <path d="M134.81,317.06a41.78,41.78,0,0,1-3.36,8.85A17.24,17.24,0,0,0,128.2,324a32.82,32.82,0,0,0,3.1-8.23Zm5.73-.69c0-.53,0-1.23,0-2-7-.12-8.23,0-9.11.22-.08-.83-.42-3-.67-4.18,1,0,1.95-.72,3.4-1.89a37.79,37.79,0,0,0,2.88-2.6c-2.91-.18-3.76-.15-4.33,0-.08-.87-.37-2.85-.62-4a4.4,4.4,0,0,0,2.5-1.62,42.9,42.9,0,0,0,6.22-8.21l3.59,2.36a55.38,55.38,0,0,1-7,8.07l2.61.28c1.22-1.39,2.48-3,3.58-4.52l3.25,2.92a70.78,70.78,0,0,1-6.38,6.75l2.86-.25a50.77,50.77,0,0,1,.7,8.2Zm-1.63.71a70.45,70.45,0,0,1-.44,8.26l-3.49.06a75.58,75.58,0,0,0,.64-8.31Zm1.5-6.06c-.06-.94-.16-1.88-.27-2.75-1,.9-2,1.79-3,2.61Zm2.42,5.74a53.26,53.26,0,0,1,.76,7.4l-3.34.54a53.13,53.13,0,0,0-.59-7.52Zm24.32-14.62a29.67,29.67,0,0,1-7.76,8.58,27,27,0,0,0,7.09,5.59,18.61,18.61,0,0,0-3.54,3.25h0l-2.26,12.29-4.33-.8.21-1.15-7.66-1.41-.22,1.19-4.11-.75,2.25-12.26-.8.2a16.11,16.11,0,0,0-1.94-3.95,30.3,30.3,0,0,0,9.38-3.37,40.13,40.13,0,0,1-2.12-4.18,24.28,24.28,0,0,1-3.19,2.2,30.21,30.21,0,0,0-2.71-3.75,24.3,24.3,0,0,0,9.78-9.09l4,2c-.53.79-1.06,1.58-1.66,2.36l6.2,1.14.74-.06ZM157.24,326l.89-4.86-7.66-1.41-.89,4.86Zm2.91-8.5a27.6,27.6,0,0,1-4.32-4.47,34,34,0,0,1-5.73,2.62Zm-5.81-14.86a27,27,0,0,0,2.46,4.7,26.2,26.2,0,0,0,4.07-3.85l-6.2-1.14Z" />
          <path d="M213.38,309.16a37.8,37.8,0,0,1-4.73,9.31c1.53,2.69,3,4.21,4.12,4,.78-.14.91-2,.3-6.46a11.58,11.58,0,0,0,4.12,1.88c.47,6.67-.61,8.57-4,9.18-2.73.5-5.23-1.37-7.5-4.75a38.74,38.74,0,0,1-6.09,5.81,20.12,20.12,0,0,0-3.6-3.05,38.92,38.92,0,0,0,7.39-6.78,70.3,70.3,0,0,1-3.72-9.68L192.06,310l.77,4.26c1.84-.68,3.72-1.4,5.57-2.08l1.06,4-5.87,2.33,1.15,6.39c.44,2.44.13,3.73-1.23,4.71s-3.54,1.56-6.58,2.07a18.1,18.1,0,0,0-2.38-4.2c2.11-.27,4.28-.7,4.94-.86s.87-.35.76-.94l-1-5.43c-2.31.92-4.54,1.83-6.45,2.56l-1.9-4.3c2.08-.72,4.69-1.61,7.55-2.67l-.9-5-7.07,1.27-.76-4.22,7.07-1.28-.77-4.26c-2,.7-4,1.33-5.84,1.9a17,17,0,0,0-1.94-3.51,96,96,0,0,0,14.28-5.82l3.67,3.24c-1.77.9-3.74,1.75-5.82,2.59l.91,5.05,7.18-1.29c-.91-3.31-1.65-6.77-2.3-10.17l4.67-.84a93.94,93.94,0,0,0,2.23,10.18l10.17-1.83L214,306l-9.72,1.75c.7,2.19,1.47,4.3,2.22,6.13a31.47,31.47,0,0,0,2.64-5.73Zm-4.22-7.74a30.25,30.25,0,0,0-6.42-5.21l3-2.89a32.73,32.73,0,0,1,6.6,4.95Z" />
          <path d="M246.11,291.39l-5.59,3.38,9.77,16.16-3.58,2.16L229.5,284.61c.47,2.25.88,4.62,1.15,7l13.86,22.93-3.68,2.22L231,300.48a30.19,30.19,0,0,1-.41,3.88,31.86,31.86,0,0,0-4.37-3c.81-4.62.31-10.8-1.14-16.44l4.22-1.09.18.73,9.14-5.52Zm-11.31-6.08,1.12,1.85,2.67-1.61-1.12-1.85Zm6.62,4.92-1.16-1.92-2.67,1.62,1.16,1.92Zm22.91,6.18c1.3,2.14,1.47,3.46.5,4.8s-2.94,2.76-6,4.6a16.49,16.49,0,0,0-3.5-3c2.06-1.07,4.29-2.45,4.92-2.88s.73-.71.35-1.32l-7.07-11.71-5.95,3.6-7.49-12.39,9.66-5.84ZM244.92,279.2l1.12,1.85,2.89-1.75-1.12-1.85Zm6.84,4.78-1.16-1.92-2.89,1.75,1.16,1.92Z" />
        </g>
        <g>
          <path d="M87.43,62l-5.52,4.82a2.27,2.27,0,0,0-2.26-.59,5.61,5.61,0,0,0-2.21,1.3q-3.19,2.77-.7,5.63a3.76,3.76,0,0,0,2.66,1.42,4.23,4.23,0,0,0,3-1.26c1.64-1.43,2.06-2.93,1.25-4.47l5.53-4.83A6.93,6.93,0,0,1,90,71.21a17.84,17.84,0,0,1-4.64,5.58q-8.64,7.54-14.11,1.28T74.5,64.33c2.59-2.26,4.87-3.6,6.86-4A6.82,6.82,0,0,1,87.43,62Z" />
          <path d="M91.31,61.05c-1.45-2.44-1.73-4.69-.84-6.77s3-4.11,6.39-6.1,6.18-2.88,8.42-2.66,4.09,1.56,5.53,4,1.73,4.7.85,6.78-3,4.12-6.38,6.12-6.19,2.87-8.44,2.64S92.75,63.5,91.31,61.05Zm6.31-3.73c1.27,2.16,3,2.55,5.35,1.19s2.81-3.11,1.53-5.26-3-2.52-5.33-1.17S96.35,55.19,97.62,57.32Z" />
          <path d="M117.52,54.92l-5.29-14.36,6.36-2.34.65,1.79.06,0a5.2,5.2,0,0,1,1.51-2.2,10.49,10.49,0,0,1,3.62-2.17,7.62,7.62,0,0,1,5-.36,5.52,5.52,0,0,1,3.4,3.64L136.22,48l-6.57,2.42L126.91,43c-.63-1.74-1.79-2.29-3.47-1.67-2,.74-2.63,2.26-1.79,4.53l2.43,6.62Z" />
          <path d="M136.84,36.73,136,32.45l3.17-.65-1-4.82L145,25.57l1,4.82,3.7-.76.88,4.28-3.7.76L148,40c.13.66.38,1.05.72,1.17a3.66,3.66,0,0,0,1.64-.11l1.83-.37.82,3.95A37.77,37.77,0,0,1,148.06,46c-2.18.45-3.72.45-4.63,0s-1.73-1.73-2.14-3.71L140,36.08Z" />
          <path d="M162.61,32.93l-7,.35a5.44,5.44,0,0,1,2.81-4.34c1.49-.79,4.12-1.27,7.88-1.46,3.6-.19,6.18.15,7.74,1s2.41,2.4,2.52,4.65l.37,7.38a4.26,4.26,0,0,0,.76,2.34l-7.15.36a7,7,0,0,1-.33-1.49c-1.46,1.49-4,2.33-7.55,2.51a11.14,11.14,0,0,1-5.65-.84,4.41,4.41,0,0,1-2.27-4q-.23-4.47,6.91-5.17l5.89-.57c1.24-.12,1.84-.57,1.81-1.35-.05-1-1.16-1.43-3.32-1.32S162.81,31.76,162.61,32.93Zm7.15,4,0-.67q-1.11.3-5.61.93c-1.26.17-1.87.71-1.83,1.6.06,1.16,1,1.69,2.69,1.6C168.27,40.24,169.86,39.08,169.76,36.94Z" />
          <path d="M203.76,36.84l-7.26-1a2.27,2.27,0,0,0-1.05-2.09A5.64,5.64,0,0,0,193,33q-4.19-.56-4.68,3.19a3.76,3.76,0,0,0,.68,2.94A4.26,4.26,0,0,0,192,40.55c2.17.29,3.56-.38,4.19-2l7.27,1a6.93,6.93,0,0,1-4.83,5.34,17.7,17.7,0,0,1-7.25.19Q180,43.54,181.05,35.3t12.45-6.65c3.41.45,5.92,1.28,7.55,2.5A6.79,6.79,0,0,1,203.76,36.84Z" />
          <path d="M207.47,36.12l1.23-4.18,3.11.91,1.39-4.73,6.71,2-1.38,4.73,3.62,1.06-1.23,4.19L217.3,39l-1.53,5.2c-.19.65-.16,1.11.1,1.37a3.61,3.61,0,0,0,1.49.68l1.8.52L218,50.66a39.33,39.33,0,0,1-5-1.12c-2.13-.62-3.49-1.35-4.09-2.16s-.71-2.35-.14-4.29L210.59,37Z" />
          <path d="M257.92,53.62l-7.77,13.19-5.83-3.43.87-1.47-.05,0a5.35,5.35,0,0,1-2.66.22,10.55,10.55,0,0,1-4-1.43A7.57,7.57,0,0,1,235.05,57a5.5,5.5,0,0,1,.66-4.93l5-8.57,6,3.55-4.12,7q-1.4,2.38.91,3.75c1.87,1.09,3.42.59,4.66-1.52l3.66-6.22Z" />
          <path d="M265.57,72.49l-4.74-5a6.87,6.87,0,0,1-2.16-3.84,4.31,4.31,0,0,1,1.24-3.13,4.11,4.11,0,0,1,4.48-1.33q2.67.68,6.61,4,7.58,6.47,4.32,10.54l-6-5.13c.58-.68.21-1.58-1.11-2.71s-2.12-1.35-2.58-.81-.07,1,.81,1.94l4.68,4.77q3.66,3.72,1,6.89a4.33,4.33,0,0,1-4.69,1.5c-1.86-.47-4.22-1.93-7.07-4.36s-4.54-4.42-5.26-6.14a4.57,4.57,0,0,1,.64-4.87L262,70.21a1.55,1.55,0,0,0,0,1.69,5.81,5.81,0,0,0,1.24,1.31c1.17,1,2,1.21,2.53.62A.89.89,0,0,0,265.57,72.49Z" />
        </g>
      </g>
      <g>
        <rect
          x="123.6"
          y="141.05"
          width="106.82"
          height="77.1"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.93"
        />
        <polygon
          points="177.01 188.36 230.58 141.05 123.43 141.05 177.01 188.36"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.93"
        />
      </g>
    </g>
  </SVG>
)

const SVGIconButton = (props) => (
  <IconButton
    variant="unstyled"
    rounded="full"
    width="full"
    height="auto"
    _hover={{
      color: 'white',
      bg: 'black',
    }}
    lineHeight="0"
    {...props}
  />
)

const WhoAreWe = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <Flex
        my="5em"
        flexDirection={responsive('row', 'column')}
        maxW="880px"
        mx="auto"
        position="relative"
      >
        <Box py={responsive(0, 12)}>
          <Media lessThan="tablet">
            <Image src={who} w="2.5em" alt="Who are we?" />
          </Media>
          <Media greaterThanOrEqual="tablet">
            <Image src={whoDesk} alt="Who are we?" />
          </Media>
        </Box>
        <Stack
          flex="1"
          pl={responsive(8, 0)}
          letterSpacing="wider"
          spacing={responsive(20, 16)}
        >
          <Text
            textAlign="justify"
            fontSize={responsive('lg', '2xl')}
            lineHeight={1.75}
          >
            由
            <Link textDecoration="underline" isExternal href="https://relab.cc">
              RE:LAB
            </Link>
            發起的新團隊，團隊裡有難相處的設計師、腦神經＆認知心理學狂熱者、不苟言笑的SEO星探、遊戲成癮的社會邊緣人等，雖然看不出來，但我們都是好人，想做出會讓人更幸福的測驗。
            <br />
            如果你有興趣，也願意相信我們，歡迎訂閱超測驗。
            <br />
            本網站測驗部分是由{' '}
            <Link
              textDecoration="underline"
              isExternal
              href="https://ooopenlab.cc"
            >
              OOOPEN Lab 超開放實驗室
            </Link>
            工具製作
          </Text>
          <HStack spacing={responsive(12, 16)} px={responsive(0, '20%')}>
            <SVGIconButton icon={<Sub />} onClick={onOpen} />
            <SVGIconButton
              as="a"
              href="mailto:superquiz@relab.cc"
              icon={<Contact />}
            />
          </HStack>
        </Stack>
      </Flex>
      <SubscriptionForm isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default WhoAreWe
