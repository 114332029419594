import styled from '@emotion/styled'
import React from 'react'
import SVG from '../../components/SVG'

const StyledSVG = styled(SVG)`
  .bg {
    fill: white;
  }
  .heart {
    fill: white;
  }
  &:hover {
    color: white;
    .bg {
      fill: black;
    }
  }
`

const SubbuttonSVG = (props) => {
  return (
    <StyledSVG viewBox="0 0 301.86 233.98" {...props}>
      <g>
        <path className="bg" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M295.3,172.91c-2.76,.27-5.52,.53-8.28,.77V87.83h-19.63c8.93-1.27,15.14-3.69,18.9-7.35,4.45-4.34,6.62-11.57,6.62-22.1V6.59H136.37v5.59h-23.01V1H60.43V12.19H14.52V62.52h-4.41v51.75h7.06v1.2c0,41.63-4.88,75.13-14.51,99.6l-1.28,3.26,3.44,.67c8.94,1.75,16.96,3.52,23.83,5.26,6.77,1.72,13.38,4.03,19.67,6.89l3.13,1.42,.9-3.32c1.67-6.18,3.07-12.84,4.18-19.83,11.98,6.02,25.19,10.62,39.34,13.68,15.48,3.36,33.51,5.56,53.62,6.54,22.42,.98,44.35,1.48,65.18,1.48s43.94-.5,69.28-1.48l2.45-.09,.32-2.44c1.55-11.62,3.02-21.07,4.39-28.09,1.32-6.79,3.73-14.21,7.16-22.05l2-4.56-4.95,.48Z" />
        <g>
          <path className="text" fill="currentColor" d="M44.64,39.04l-8.38-1.25c-3.38-.5-5.08-2.25-5.08-5.23,0-4.08,3.58-6.12,10.75-6.12,4.04,0,7.04,.52,8.98,1.56,1.84,.99,2.83,2.5,2.97,4.53h-8.54c0-1.25-1.14-1.88-3.41-1.88-1.96,0-2.94,.44-2.94,1.33,0,.55,.32,.88,.96,.96l8.77,1.3c3.99,.59,5.99,2.39,5.99,5.42,0,2.27-.96,3.95-2.89,5.04-1.93,1.08-4.95,1.63-9.06,1.63-3.52,0-6.2-.36-8.02-1.07-2.53-.99-3.81-2.72-3.83-5.18h8.85c.04,.75,.43,1.25,1.17,1.51,.45,.14,1.15,.21,2.11,.21,1.94,0,2.92-.5,2.92-1.51,0-.69-.44-1.11-1.33-1.25Z" />
          <path className="text" fill="currentColor" d="M78.05,31.44v14.24h-6.3v-1.59h-.05c-.4,.54-1.08,1.02-2.03,1.43-1.14,.49-2.43,.73-3.85,.73-1.91,0-3.41-.43-4.5-1.3-1.2-.96-1.8-2.38-1.8-4.27v-9.24h6.51v7.55c0,1.72,.83,2.58,2.5,2.58,2.01,0,3.02-1.14,3.02-3.41v-6.72h6.51Z" />
          <path className="text" fill="currentColor" d="M83.78,45.68V26.44h6.51v6.61c1.12-1.34,3.01-2,5.67-2,2.3,0,4.18,.61,5.65,1.82,1.65,1.35,2.48,3.28,2.48,5.78s-.82,4.43-2.47,5.78c-1.46,1.21-3.33,1.82-5.62,1.82-2.71,0-4.66-.69-5.86-2.08h-.05v1.51h-6.3Zm6.51-7.03c0,2.15,1.19,3.23,3.57,3.23s3.57-1.08,3.57-3.23c0-.95-.29-1.72-.88-2.29-.64-.62-1.53-.94-2.66-.94-2.39,0-3.59,1.08-3.59,3.23Z" />
          <path className="text" fill="currentColor" d="M119.68,40.68l-6.35-.65c-1.8-.19-3.09-.66-3.85-1.41-.68-.68-1.02-1.67-1.02-2.97,0-1.67,.79-2.88,2.37-3.65,1.53-.76,3.9-1.15,7.11-1.15,6.18,0,9.32,1.61,9.43,4.84h-7.34c0-.83-.81-1.25-2.42-1.25s-2.32,.33-2.32,.99c0,.49,.58,.78,1.74,.88l6.2,.55c3.23,.28,4.84,1.71,4.84,4.3,0,1.8-.8,3.1-2.41,3.89-1.61,.79-4.15,1.18-7.64,1.18s-5.88-.39-7.43-1.16c-1.55-.77-2.38-2.05-2.49-3.84h7.65c.09,.61,.42,1.01,.99,1.22,.35,.12,.9,.18,1.67,.18,1.44,0,2.16-.36,2.16-1.09,0-.54-.29-.83-.88-.89Z" />
          <path className="text" fill="currentColor" d="M153.22,37.16h-6.82c-.07-.82-.48-1.42-1.22-1.8-.57-.29-1.35-.44-2.34-.44-2.62,0-3.93,1.17-3.93,3.52,0,1.11,.33,1.99,.99,2.63s1.62,.96,2.89,.96c2.03,0,3.24-.79,3.62-2.37h6.82c-.3,2.64-1.56,4.48-3.8,5.52-1.56,.71-3.78,1.07-6.67,1.07-7.12,0-10.67-2.58-10.67-7.73s3.56-7.66,10.67-7.66c3.2,0,5.62,.46,7.27,1.38,1.79,.99,2.86,2.63,3.2,4.92Z" />
          <path className="text" fill="currentColor" d="M158.11,45.68v-14.24h6.3v2.73h.05c1.6-2.08,3.62-3.12,6.07-3.12,.73,0,1.44,.05,2.13,.16v5.83c-1.39-.35-2.47-.52-3.26-.52-3.19,0-4.79,1.28-4.79,3.83v5.34h-6.51Z" />
          <path className="text" fill="currentColor" d="M177.12,30.03v-3.59h6.51v3.59h-6.51Zm0,15.65v-14.24h6.51v14.24h-6.51Z" />
          <path className="text" fill="currentColor" d="M189.36,45.68V26.44h6.51v6.61c1.12-1.34,3.01-2,5.67-2,2.3,0,4.18,.61,5.64,1.82,1.66,1.35,2.48,3.28,2.48,5.78s-.82,4.43-2.47,5.78c-1.46,1.21-3.33,1.82-5.62,1.82-2.71,0-4.66-.69-5.86-2.08h-.05v1.51h-6.3Zm6.51-7.03c0,2.15,1.19,3.23,3.57,3.23s3.57-1.08,3.57-3.23c0-.95-.3-1.72-.89-2.29-.64-.62-1.53-.94-2.65-.94-2.4,0-3.59,1.08-3.59,3.23Z" />
          <path className="text" fill="currentColor" d="M227.84,41.41h6.93c-.5,1.53-1.59,2.71-3.25,3.54-1.74,.87-4.04,1.3-6.9,1.3-7.17,0-10.75-2.57-10.75-7.71,0-2.45,.89-4.34,2.67-5.68,1.78-1.34,4.27-2,7.49-2,7.31,0,10.92,3.07,10.83,9.22h-14.32c0,.62,.29,1.18,.89,1.67,.73,.61,1.74,.91,3.04,.91,1.6,0,2.72-.42,3.38-1.25Zm-7.32-4.61h7.76c-.07-.76-.45-1.37-1.15-1.82-.69-.45-1.6-.68-2.71-.68-2.29,0-3.59,.83-3.91,2.5Z" />
        </g>
        <g id="b">
          <path className="heart" fill="currentColor" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M153.72,153.72c-.62-.57-1.37-1.21-2.06-1.91-11.86-11.91-23.7-23.85-35.58-35.74-7.12-7.13-8.85-15.52-5.49-24.83,3.12-8.62,12.45-15.05,21.74-15.11,6.11-.03,11.59,2.03,15.94,6.5,1.69,1.74,3.2,3.66,4.89,5.6,2.95-2.69,5.25-4.94,7.71-7,9.06-7.58,22.17-6.64,30.79,2.12,8.62,8.77,9.57,22.14,1.48,30.76-10.51,11.2-21.53,21.91-32.36,32.81-2.26,2.27-4.62,4.45-7.05,6.79Z" />
        </g>
        <g>
          <path className="text" fill="currentColor" d="M132.03,188.1h5.98v3.01h-12.61v-3.01h5.5c-.48-1.22-1.31-2.82-2.05-4.03l2.98-1.38c.86,1.31,1.86,3.07,2.33,4.26l-2.14,1.15Zm-5.22,4.45h10.34v2.88h-10.34v-2.88Zm0,4.29h10.27v2.88h-10.27v-2.88Zm10.18,14.11h-6.91v1.34h-3.23v-11.04h10.14v9.7Zm-6.91-6.66v3.58h3.65v-3.58h-3.65Zm24.99-15.33h-4.13v18.78c0,2.18-.51,3.26-1.95,3.9-1.44,.64-3.62,.71-6.72,.71-.19-1.12-.9-2.94-1.5-3.94,2.14,.13,4.67,.1,5.34,.1,.67-.03,.93-.22,.93-.83v-18.72h-8.38v-3.71h16.41v3.71Z" />
          <path className="text" fill="currentColor" d="M168.51,193.51v19.2h-3.55v-28.86h12.35v9.66h-8.8Zm0-7.23v1.28h5.22v-1.28h-5.22Zm5.22,4.83v-1.41h-5.22v1.41h5.22Zm18.3,17.5c0,1.7-.29,2.59-1.31,3.17-.99,.58-2.43,.64-4.42,.64-.06-.61-.29-1.47-.54-2.21-.45,.51-1.09,.71-1.95,.71h-2.02c-2.53,0-3.04-.87-3.04-3.42v-2.62h-1.66c-.45,3.14-1.7,5.25-5.79,6.59-.35-.77-1.15-1.95-1.79-2.53,3.23-.8,4.16-2.11,4.48-4.06h-1.7v-5.05c-.42,.26-.8,.48-1.22,.7-.51-.58-1.54-1.38-2.18-1.79,2.21-1.12,4.64-2.91,5.73-4.58l2.94,.9c-.93,1.31-2.24,2.56-3.68,3.65h9.28c-1.28-1.05-2.85-2.14-4.19-2.97l1.98-1.54c2.21,1.31,5.06,3.33,6.5,4.77l-2.11,1.7c-.26-.29-.58-.61-.96-.93v5.15h-2.5v2.62c0,.67,.03,.77,.45,.77h1.06c.45,0,.54-.29,.64-2.24,.58,.45,1.86,.9,2.66,1.06-.1,.86-.19,1.57-.38,2.05,.7,.03,1.31,.03,1.57,0,.38,0,.51-.13,.51-.54v-15.04h-8.96v-9.66h12.61v24.73Zm-16.8-5.92h6.02v-1.86h-6.02v1.86Zm7.81-16.38v1.28h5.34v-1.28h-5.34Zm5.34,4.83v-1.41h-5.34v1.41h5.34Z" />
          <path className="text" fill="currentColor" d="M206.91,197.44c-1.06-.99-3.23-2.27-4.99-3.07l2.08-2.79c1.76,.67,4.03,1.79,5.12,2.79l-2.21,3.07Zm2.75,4.67c-1.06,3.42-2.46,7.1-3.78,10.24l-3.42-1.95c1.18-2.46,2.88-6.53,4.13-10.27l3.07,1.98Zm-1.57-12.99c-1.02-1.02-3.2-2.43-4.96-3.23l2.14-2.81c1.73,.73,3.97,2.02,5.06,2.97l-2.24,3.07Zm7.04,17.02c-1.06,2.43-2.78,5.05-4.32,6.65-.7-.58-2.14-1.5-2.98-1.95,1.5-1.44,2.98-3.58,3.84-5.66l3.46,.96Zm5.31-1.22h-9.66v-20.86h9.66v20.86Zm-3.33-17.57h-3.14v2.69h3.14v-2.69Zm0,5.73h-3.14v2.72h3.14v-2.72Zm0,5.79h-3.14v2.75h3.14v-2.75Zm1.92,6.05c1.18,1.47,2.69,3.55,3.36,4.9l-3.01,1.79c-.61-1.34-2.05-3.49-3.23-5.09l2.88-1.6Zm6.3-.38h-3.3v-18.49h3.3v18.49Zm5.44-21.69v25.57c0,1.95-.35,2.94-1.47,3.52-1.12,.61-2.78,.74-5.28,.74-.13-.99-.61-2.59-1.09-3.65,1.63,.06,3.17,.06,3.68,.06,.54-.03,.7-.19,.7-.67v-25.57h3.46Z" />
          <path className="text" fill="currentColor" d="M240.19,209.83c.61-1.86,1.09-4.8,1.31-6.91l1.89,.67c-.19,2.11-.61,5.15-1.15,6.94l-2.05-.7Zm19.13-6.43c-.19,.83-.42,1.66-.67,2.46,1.15,.71,2.37,1.63,3.01,2.34l-1.86,2.46c-.51-.54-1.38-1.31-2.27-1.98-.77,1.6-1.7,2.97-2.75,4.06-.48-.54-1.66-1.5-2.4-2.05-.1,.32-.19,.54-.32,.7-.48,.71-1.02,.96-1.76,1.06-.64,.13-1.63,.16-2.66,.13-.03-.87-.35-2.05-.74-2.82,.8,.1,1.47,.1,1.79,.1,.35,0,.58-.03,.8-.35,.19-.32,.32-1.09,.45-2.75l-1.12,.42c-.22-1.12-.77-2.85-1.28-4.13l1.34-.45c.42,.93,.83,2.11,1.12,3.1,.06-1.02,.1-2.3,.16-3.84h-8.35v-17.82h11.07v3.1h-3.26v2.11h2.75v.74c3.39-1.89,6.5-4.8,8.19-7.58l3.39,1.18c-.19,.35-.42,.67-.64,.99,1.92,1.7,4.7,3.39,7.17,4.32-.54,.87-1.18,2.53-1.41,3.49-.99-.51-1.98-1.09-2.98-1.76v2.11h-9.41v-1.6c-.74,.48-1.47,.93-2.21,1.38-.45-.61-1.41-1.63-2.11-2.21v1.76h-2.75v1.89h2.85v2.78h-2.85v2.02h3.55s0,.83-.03,1.22c-.16,5.95-.32,9.02-.7,10.49,1.89-1.79,3.1-4.57,3.68-7.62l3.2,.54Zm-14.02,.06c.16,1.82,.26,4.22,.26,5.82l-1.66,.19c.06-1.6-.03-4.03-.16-5.89l1.57-.13Zm-.54-16.32v2.11h1.98v-2.11h-1.98Zm1.98,4.93h-1.98v1.89h1.98v-1.89Zm-1.98,6.69h1.98v-2.02h-1.98v2.02Zm2.4,4.35c.38,1.66,.8,3.78,.93,5.15l-1.6,.32c-.1-1.41-.48-3.58-.86-5.22l1.54-.26Zm6.85-8.67h6.85v7.78h-6.85v-7.78Zm2.46,5.38h1.76v-3.01h-1.76v3.01Zm8.51-9.95c-1.25-.93-2.46-1.89-3.55-2.88-.9,1.03-1.95,1.98-3.07,2.88h6.62Zm2.34,13.41c-.22,1.28-.51,2.53-.9,3.71,1.34,.89,2.88,2.02,3.71,2.81l-2.21,2.59c-.61-.67-1.7-1.57-2.78-2.4-.54,1.02-1.18,1.98-1.89,2.75-.54-.57-1.95-1.63-2.75-2.11,1.86-1.82,2.94-4.73,3.42-7.94l3.39,.58Zm-5.44-8.83h7.1v7.78h-7.1v-7.78Zm2.46,5.38h2.02v-3.01h-2.02v3.01Z" />
        </g>
      </g>
    </StyledSVG>
  )
}

export default SubbuttonSVG
