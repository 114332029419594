import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/modal'
import React from 'react'

const SubscriptionForm = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent px="0" py="0">
        <ModalCloseButton />
        <ModalBody px="0" py="0" bg="#EFF2F7">
          <iframe
            title="搶先預約"
            width="540"
            height="580"
            src="https://d67d054e.sibforms.com/serve/MUIEAKyri_hEICzvjb6jLjoI2DS0qvLVI0sFwfslvg30ETSluola76hUT-0AZGYWhyWiVuepSEtaRVHoYOdgFBeRiUuUGIYTYwdGfdJXtCV9sq0Bn-Q4xeSWMB1sFVIhaDRNnYxH-Ekyw62gqZZpgdNyXuycNbbZwh33Af6JWJNOaiEQbmjM39t-QUivfK_uJ7jcH2bsS8Gn-7pB"
            frameborder="0"
            scrolling="auto"
            allowfullscreen
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '100%',
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SubscriptionForm
