import { forwardRef } from '@chakra-ui/react'
import React from 'react'

import SVG from './SVG'

const ArrowD = forwardRef((props, ref) => {
  return (
    <SVG viewBox="0 0 20 35" {...props} ref={ref}>
      <g>
        <line stroke="currentColor" x1="9.97" y1=".55" x2="9.97" y2="34.45" />
        <path stroke="currentColor" d="M10.12,34.37c0-4.7,3.81-8.52,8.52-8.52" />
        <path stroke="currentColor" d="M9.88,34.37c0-4.7-3.81-8.52-8.52-8.52" />
      </g>
    </SVG>
  )
})

export default ArrowD
