import { Circle } from '@chakra-ui/react'
import React from 'react'

const gradients = {
  a: ['#FF915C', '#FDDC9A'],
  b: ['#FDF7C0', '#FFA338'],
  c: ['#FFF3B5', '#FD9286'],
}

const Bokeh = ({ variant = 'a', blur = '10vmin', ...props }) => {
  return (
    <Circle
      filter={`blur(${blur})`}
      bgGradient={`linear(to-l, ${gradients[variant]})`}
      size="50vmin"
      {...props}
    />
  )
}

export default Bokeh
