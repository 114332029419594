import { forwardRef } from '@chakra-ui/react'
import React from 'react'

import SVG from './SVG'

const Arrow = forwardRef((props, ref) => {
  return (
    <SVG viewBox="0 0 128 128" {...props} ref={ref}>
      <g fill="none" stroke="currentColor" strokeWidth="2px">
        <line class="a" x1="22.75" y1="63.86" x2="105.25" y2="63.86"/>
        <path class="a" d="M104.92,63.21A37.39,37.39,0,0,1,67.53,25.82"/>
        <path class="a" d="M104.92,64.26a37.39,37.39,0,0,0-37.39,37.39"/>
      </g>
    </SVG>
  )
})

Arrow.Down = forwardRef((props, ref) => <Arrow transform="rotate(90deg)" {...props} ref={ref} />)

export default Arrow
