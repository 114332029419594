import { Box, forwardRef, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'

import { responsive } from '../../components/ThemeProvider/theme'
import Bokeh from './Bokeh'
import lifeBook from './life-is-a-book.svg'

const LifeIsABook = forwardRef((props, ref) => {
  return (
    <Box pos="relative" ref={ref}>
      <Box pos="absolute" left="0" top="0" transform={responsive('translate(-20%)', 'translate(-20%, 33%)')} pointerEvents="none">
        <Bokeh opacity="0.5" variant='a' size={responsive('80vmin', '80vmin')} />
      </Box>
      <Stack
        mx="auto"
        w={responsive('100%', '50%')}
        justifyContent="center"
        spacing={responsive('10', '10')}
        pos="relative"
        h={responsive('auto', 'calc(100vh - var(--chakra-sizes-headerHeight))')}
        py={20}
      >
        <Image userSelect="none" pointerEvents="none" w="full" src={lifeBook} alt="Life is a book. But peple are used to reading others' stories" />
        <Stack fontSize={responsive('1em', '1.5em')} lineHeight="1.75" letterSpacing="wider">
          <Text textAlign="justify">
            「<b>你要搞清楚自己人生的劇本</b>——不是你父母的續集，不是你子女的前傳，更不是你朋友的外篇。對待生命你不妨大膽冒險一點，因為最終你要失去它。如果這世界上真有奇蹟，那只是努力的另一個名字。<b>生命中最難的階段不是沒有人懂你，而是你不懂你自己。</b>」
          </Text>
          <Text textAlign="right">-尼采</Text>
        </Stack>
      </Stack>
    </Box>
  )
})

export default LifeIsABook
