import { graphql } from 'gatsby'
import Page from '../containers/HomePage'

export default Page

export const pageQuery = graphql`
  query StickyArticles {
    allWpPost(
      filter: {isSticky: {eq: true}}
      limit: 3
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "YYYY-MM-DD")
        title
      }
    }
  }
`
