import { Box, Circle, Flex, IconButton, Image, Stack, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import random from 'lodash/random'
import shuffle from 'lodash/shuffle'
import React, { useMemo } from 'react'

import Arrow from '../../components/Arrow'
import { responsive } from '../../components/ThemeProvider/theme'
import Bokeh from './Bokeh'

import heading from './heading.svg'

const MotionBox = motion(Box)

const Heading = () => {
  return (
    <Flex
      width={responsive('full', '55%')}
      height="calc(100vh - var(--chakra-sizes-headerHeight))"
      alignItems="center"
      pos="relative"
      px={{ base: 0, lg: 8 }}
    >
      {useMemo(() => shuffle(['a', 'b', 'c']).map(variant => (
        <MotionBox
          position="absolute"
          left={responsive("50%", '135%')}
          top={responsive("20%", '25%')}
          key={variant}
          initial={{
            x: '-50%',
          }}
          animate={{
            rotate: 360,
          }}
          transition={{
            ease: 'linear',
            duration: random(5, 7),
            repeat: Infinity,
          }}
        >
          <Bokeh
            size={responsive('80vmin', '60vmin')}
            variant={variant}
            transform={`translate(${random(-20, 20)}%, ${random(-20, 20)}%)`}
          />
        </MotionBox>
      )), [])}

      <Stack w="full" spacing={responsive(24, 10)} pos="relative">
        <Image userSelect="none" pointerEvents="none" w='full' src={heading} alt="Do Super Quiz, Become SuperHuman" />
        <Text lineHeight="2" letterSpacing="widest" fontSize={responsive('1em', '1.5em')}>
          我們將實用的心理學理論設計成測驗<br />
          不是為了提供答案　而是為了啟發思考<br />
          在思考中不斷地超越自己
        </Text>
      </Stack>
      <Box
        pos="absolute"
        right={responsive("50%", '-80%')}
        bottom="2em"
        transform="translateX(50%)"
      >
        <IconButton
          size="lg"
          icon={<Arrow.Down width="2em" />}
          variant="outline"
          rounded="full"
          onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}
          _hover={{
            bg: 'black',
            color: 'white',
          }}
          _active={{
            bg: 'blackAlpha.700',
            color: 'white',
          }}
        />
      </Box>
    </Flex>
  )
}

export default Heading
