import { Box, Stack } from '@chakra-ui/react'
import React from 'react'
import { responsive } from '../../components/ThemeProvider/theme'
import StarTitle from './StarTitle'
import PostArchive from '../PostArchive'
import Button from '../../components/Button'
import Arrow from '../../components/Arrow'

const FeatureArticles = ({ posts }) => {
  return (
    <Stack
      maxW="1500px"
      justifyContent="center"
      spacing={responsive(4, 24)}
      pos="relative"
      py="20vh"
    >
      <StarTitle>推薦閱讀特輯</StarTitle>
      <PostArchive posts={posts} />
      <Box textAlign={responsive('center', 'right')} px="3em">
        <Button
          letterSpacing="widest"
          to="/articles/"
          fontSize="1.5rem"
          size="lg"
          rounded="full"
          variant="outline"
          color="black"
          rightIcon={<Arrow w="1.5em" />}
          _hover={{
            bg: 'black',
            color: 'white',
          }}
          _active={{
            bg: 'blackAlpha.700',
            color: 'white',
          }}
        >看全部特輯</Button>
      </Box>
    </Stack>
  )
}

export default FeatureArticles
