import { AspectRatio, Box, forwardRef, LinkBox, LinkOverlay, Stack, Text, useConst } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { nanoid } from 'nanoid/non-secure'
import { StaticImage } from 'gatsby-plugin-image';
import { useHover } from 'react-use';
import styled from '@emotion/styled';

import StarTitle from './StarTitle'
import SVG from '../../components/SVG';
import Link from '../../components/Link';
import { responsive } from '../../components/ThemeProvider/theme';

const StyledBox = styled(Box)`
.slick-dots li button:before {
  content: "";
  border-radius: 50%;
  background: transparent;
  border: 1px solid black;
  opacity: 1;
  width: 12px;
  height: 12px;
  line-height: 12px;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  background: black;
}
`

const quizes = [
  { name: '愛之語測驗－愛情', image: <StaticImage layout="fullWidth" src="quiz-love.png" />, href: '/q/love-languages/lover/' },
  { name: '叢心出花', image: <StaticImage layout="fullWidth" src="xiahai-flower.png" />, href: '/q/xiahai-flower/' },
  { name: '愛之語測驗－友情', image: <StaticImage layout="fullWidth" src="quiz-friend.png" />, href: '/q/love-languages/friend/start/' },
  { name: '在愛裡，你是哪衣種人', image: <StaticImage layout="fullWidth" src="quiz-closet.png" />, href: '/q/closet/', isExternal: true },
  { name: '依附理論分析', image: <StaticImage layout="fullWidth" src="quiz-attachment.png" />, href: 'https://ooopenlab.cc/quiz/Nkn1tVyDkOh2dtsOP33Y?ref=qpCJD4', isExternal: true },
  { name: '亞斯伯格量表', image: <StaticImage layout="fullWidth" src="quiz-baron-cohen.png" />, href: 'https://ooopenlab.cc/quiz/WKDXgoHwVLn8h1gsYrKc?ref=qYNUd4', isExternal: true },
]

const QuizHover = forwardRef(({ text, ...props }, ref) => {
  const pathId = useConst(() => nanoid())
  const centerText = useMemo(() => {
    const diff = 10 - text.length
    const spaces = Math.floor(diff / 2)
    if (diff % 2) {
      return ` ${'　'.repeat(spaces)}${text}`
    }
    return `${'　'.repeat(spaces)}${text}`
  }, [text])

  return (
    <SVG viewBox="0 0 346 346" ref={ref} transition="0.3s opacity" {...props}>
      <g>
        <path id={pathId} d="M45.65,175.56a127.35,127.35,0,0,0,254.7,0" />
        <g>
          <circle cx="173" cy="173" r="173" />
          <g>
            <rect x="114.99" y="175.25" width="109.99" height="1.09" transform="translate(-74.52 171.69) rotate(-45)" fill="#fff" />
            <path d="M183.44,147.4A35.69,35.69,0,0,1,158,136.88l.77-.77a34.84,34.84,0,0,0,49.28,0l.76.77A35.66,35.66,0,0,1,183.44,147.4Z" fill="#fff" />
            <path d="M208.78,187.63a35.92,35.92,0,0,1,0-50.82l.76.77a34.86,34.86,0,0,0,0,49.28Z" fill="#fff" />
          </g>
          <g>
            <path d="M80.67,96.1,68.29,83.24,79,72.94a10.4,10.4,0,0,1,3.49-2.47,4.16,4.16,0,0,1,2.42,0,5.08,5.08,0,0,1,2.33,1.41c1.51,1.57,1.8,3.18.85,4.84l0,0a2.77,2.77,0,0,1,3.37.58L94.39,80a3.63,3.63,0,0,0,2.09.9l-5.55,5.35A16.92,16.92,0,0,1,88.32,84q-1.56-1.44-2.79-.27l-3.6,3.47,3.82,4ZM76.49,81.59,78.81,84l3.6-3.47c.94-.9,1-1.75.24-2.56S81,77.24,80,78.19Z" fill="#fff" />
            <path d="M98.61,79.5l-9.72-15L104.8,54.19l2.37,3.65-10,6.5,1.3,2,9.29-6L110.13,64l-9.29,6,1.31,2,10-6.5,2.37,3.65Z" fill="#fff" />
            <path d="M114.66,68.89l.21-19.4,8-3.3L136.7,59.78l-6.93,2.87L128.3,61l-6.8,2.8.09,2.18ZM125.2,57.61,121,52.82l0,0,.37,6.39Z" fill="#fff" />
            <path d="M137.89,59.53,134.52,42,148,39.41c3.07-.59,5.46-.37,7.15.66s3,3.21,3.61,6.36.25,5.4-1,7.14-3.3,2.77-6.26,3.34Zm4.4-14.44L144,53.78l4-.77q4.44-.85,3.61-5.2c-.56-2.89-2.32-4.06-5.28-3.49Z" fill="#fff" />
            <path d="M174.68,54.64,177,36.94l9.84,1.3,1.54,10.67h.05L192.7,39l9.84,1.3L200.2,58l-6.34-.84,1.67-12.64-5.42,12.14-5.34-.7-2.08-13.14L181,55.48Z" fill="#fff" />
            <path d="M205.76,50.34q2.15-5.67,7.13-6.13a23.37,23.37,0,0,1,14.4,5.44q3.44,3.65,1.3,9.3t-7.12,6.13a18.19,18.19,0,0,1-7.67-1.5,18.07,18.07,0,0,1-6.74-3.94Q203.63,56,205.76,50.34Zm7,2.65q-1.78,4.73,2.61,6.38t6.18-3.06q1.78-4.73-2.61-6.39T212.78,53Z" fill="#fff" />
            <path d="M227.78,68.71l9.65-15,12.49,8a10,10,0,0,1,3.21,2.83,4.13,4.13,0,0,1,.53,2.36,5.18,5.18,0,0,1-.83,2.59c-1.18,1.83-2.68,2.48-4.52,1.94l0,0a2.77,2.77,0,0,1,.22,3.41l-1.86,3.44a3.66,3.66,0,0,0-.4,2.24l-6.48-4.16a16.91,16.91,0,0,1,1.51-3c.7-1.24.57-2.16-.39-2.78l-4.2-2.7-3,4.63Zm13.15-7.42-1.81,2.82,4.21,2.7c1.09.7,1.94.58,2.54-.36s.33-1.78-.82-2.51Z" fill="#fff" />
            <path d="M248.06,82.32,260.37,69.4l13.74,13.09-3,3.15-8.64-8.23-1.65,1.74,8,7.64-3,3.15-8-7.64L256.16,84l8.63,8.22-3,3.15Z" fill="#fff" />
          </g>
        </g>
        <text
          fontSize="30.98"
          fill="#fff"
          fontFamily="NotoSansCJKtc-Bold-B5pc-H, Noto Sans CJK TC, Noto Sans TC"
          fontWeight="bold"
          letterSpacing="10.5"
        >
          <textPath alignmentBaseline="middle" href={`#${pathId}`}>
            {centerText}
          </textPath>
        </text>
      </g>
    </SVG>
  )
})

const QuizCard = ({ name, image, to, href, isExternal }) => {
  const element = (hovered) => (
    <Box
      pos="relative"
      rounded="full"
      outline="1px solid"
      transition="transform 0.2s ease-in-out"
      _hover={{
        transform: responsive('scale(1.05)', 'scale(1.2)'),
      }}
      as={LinkBox}
    >
      <AspectRatio ratio={1} transform="translateZ(0)" overflow="hidden" rounded="full">
        {image}
      </AspectRatio>
      <Box pos="absolute" left="0" top="0" right="0" bottom="0">
        <LinkOverlay href={href} to={to} as={Link} isExternal={isExternal}>
          <QuizHover opacity={hovered ? 1 : 0} text={name} />
        </LinkOverlay>
      </Box>
    </Box>
  );
  const [hoverable] = useHover(element);

  return (
    <Box p={responsive('5%', '10%')}>
      {hoverable}
    </Box>
  )
}

const FeatureQuizes = () => {
  return (
    <Stack
      maxW="1500px"
      mx={responsive('-4em', 'auto')}
      spacing={responsive(4, 24)}
      h={responsive('auto', 'calc(100vh - var(--chakra-sizes-headerHeight))')}
      justifyContent="center"
      pb="4rem"
      pos="relative"
      zIndex={1}
      id="quizes"
      pt="var(--chakra-sizes-headerHeight)"
    >
      <Stack spacing={4}>
        <StarTitle mx={responsive('4em', 0)}>推薦心理測驗</StarTitle>
        <Text textAlign="center" fontSize={responsive('lg', 'xl')} letterSpacing="wider">
          與專家合作<b>根據理論基礎打造</b><br />
          既<b>人性化又有質感</b>的心理測驗
        </Text>
      </Stack>
      <StyledBox px="2em">
        <Slider
          infinite
          dots
          slidesToShow={4}
          slidesToScroll={1}
          centerMode
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {quizes.map((quiz, i) => (
            <QuizCard key={i} {...quiz} />
          ))}
        </Slider>
      </StyledBox>
    </Stack>
  )
}

export default FeatureQuizes
