import { Box, Button } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react'
import { useIntersection } from 'react-use';
import ArrowD from '../../components/ArrowD';

import Container from '../../components/Container'
import SVG from '../../components/SVG';
import { Media, responsive } from '../../components/ThemeProvider/theme';
import Bokeh from './Bokeh';
import FeatureArticles from './FeatureArticles';
import FeatureQuizes from './FeatureQuizes';
import Heading from './Heading';
import LifeIsABook from './LifeIsABook';
import Subscribe from './Subscribe';
import WhoAreWe from './WhoAreWe';

const LongArrow = props => (
  <SVG viewBox="0 0 250 54" w="3.5em" h={responsive('2.5em', 'auto')} display={responsive('block', 'none')} {...props}>
    <line x1="4.76" y1="27.09" x2="245.24" y2="27.09" fill="none" stroke="currentColor" strokeWidth="1.75"/>
    <path d="M245,26.64A25.89,25.89,0,0,1,219.12.75" fill="none" stroke="currentColor" strokeWidth="1.75"/>
    <path d="M245,27.36a25.89,25.89,0,0,0-25.89,25.89" fill="none" stroke="currentColor" strokeWidth="1.75"/>
  </SVG>
)

const IndexPage = ({ data: { allWpPost: { nodes: posts } } }) => {
  const ref = useRef()
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const [showButton, setShowButton] = useState()
  useEffect(() => {
    if (intersection && intersection.intersectionRatio > 0) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }, [intersection])
  return (
    <Container w="full" maxWidth="1750px" px={responsive(7, 8)}>
      <Heading />
      <LifeIsABook ref={ref} />
      <FeatureQuizes />
      <Box pos="absolute" right={responsive(0, '-20%')} pointerEvents="none" transform="translateY(-20vh)">
        <Bokeh variant="b" opacity="0.5" size="90vmin" />
      </Box>
      <FeatureArticles posts={posts} />
      <Subscribe />
      <WhoAreWe />
      {showButton && ((
        <Box
          pos="fixed"
          bottom={responsive(0, '66%')}
          right={responsive(0)}
          w={responsive('full', '3.5em')}
          zIndex="docked"
          transform={responsive('none', 'translateY(50%)')}
        >
          <Button
            isFullWidth
            bg="black"
            color="white"
            rounded="none"
            fontWeight={400}
            leftIcon={<LongArrow />}
            rightIcon={<LongArrow />}
            display={responsive('inline-flex', 'inline-block')}
            whiteSpace={responsive('nowrap', 'normal')}
            py={responsive(0, "4")}
            h="auto"
            fontSize={responsive('1.25em')}
            letterSpacing="wider"
            onClick={() => document.getElementById('quizes').scrollIntoView({ behavior: 'smooth' })}
            _active={{
              bg: 'black',
              color: 'white',
            }}
            _hover={{
              bg: 'blackAlpha.800',
              color: 'white',
            }}
          >
            開始測驗<Box display="inline-block" w={responsive(2, 4)} />認識自己
            <Media greaterThan={'mobile'}>
              <ArrowD mt="0.375em" />
            </Media>
          </Button>
        </Box>
      ))}
    </Container>
  )
}

export default IndexPage
